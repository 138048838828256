
.rightSwipper{
  background-color: #61dafb;
  width: 50%;
  height: 100vh;
}
.leftSwipper{
  background-color:#222;
  width: 50%;
  height: 100vh;
  animation:swipper 2s   infinite top linear;
}
@keyframes swipper{
  0%{
    transform: translateY(0)
  }
  0%{
    transform: translateY(-4000px)
  }
}
.maincontainer{
  width: 100%;
  
  height: 100vh;
 
}
.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}