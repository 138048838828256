.HomeMainContainer {
	width: 100%;
	height: 100vh;
	display: flex;
	flex-direction: column;
	background-image: url(../../images/bg.jpg);
}

.HomepageContainer {
	width: 100%;
	height: 100vh;
	/* position: relative; */
	/* background-image: url(../../images/bg.jpg); */

	background: #1c92d2;
	/* fallback for old browsers */
	background: -webkit-linear-gradient(to right, #f2fcfe, #1c92d2);
	/* Chrome 10-25, Safari 5.1-6 */
	background: linear-gradient(to right, #f2fcfe, #1c92d2);
	/* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.HomepageloweContainer {
	width: 100%;
	height: 100vh;
	display: flex;
	flex-direction: row;
	background: #1c92d2;
	/* fallback for old browsers */
	background: -webkit-linear-gradient(to right, #f2fcfe, #1c92d2);
	/* Chrome 10-25, Safari 5.1-6 */
	background: linear-gradient(to right, #f2fcfe, #1c92d2);
	/* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.SocialSite {
	position: absolute;
	/* display: flex; */
	justify-content: center;
	/* align-items: center; */
	right: 2%;
	top: 30%;
	height: 50%;
	/* width: 7%; */
}

.Project {
	width: 40%;
	height: 40vh;
	background-color: aqua;
	margin-top: 3%;
	opacity: 1;
	justify-content: center;
	display: flex;
}




.ProjectRight {
	position: relative;
	transition: all 1s;
	overflow: hidden;
	width: 40%;
	height: 40vh;
	z-index: 2;
	background-color: aqua;
	margin-top: 3%;
	margin-bottom: 2%;
	float: right;
	justify-content: center;
	display: flex;
	/* background-image: url('../../images/images.jpeg') */
}

.ProjectRight::after {
	content: '';
	position: absolute;
	left: 0;
	top: 10%;
	width: 100%;
	height: 100%;
	bottom: 0;
	z-index: -2;
	transition: all 0.35s;
}

.ProjectRight::before {
	content: '';
	/* width: 0%; */
	height: 100%;
	position: absolute;
	left: 10%;
	bottom: 10%;
	z-index: -2;
}

.Project:hover {
	color: #002bff;
}

.Project:hover::after {
	width: 100%;
}

.ProjectLeft {
	display: block;
	position: absolute;
	/* width: 100%;
    height: 100%; */
	width: 40%;
	right: 5%;
	opacity: 1;
	margin-top: 5%;
	height: 40%;
	/* background-color: #1c1d25; */
	/* box-shadow: 0 20px 80px 0 rgba(0, 0, 0, .45); */
	background-position: 50%;
	background-size: cover;
	background-repeat: no-repeat;
	transition: all 0.35s ease;
}

.Image {
	width: 100%;
	height: 100%;
}

.ProjectLeft::before {
	content: '';
	position: absolute;
	width: 0%;
	top: 0px;
	opacity: 0.5;
	right: 0px;
	height: 100%;
	background-color: #77a1d3;
	transition: width 0.3s;
}



.fa {
	/* padding: 20px; */
	font-size: 2.5em;
	width: 90%;
	height: 90%;
	text-align: center;
	text-decoration: none;
	margin: 10px 5px;
	border-radius: 50%;
}

.fa:hover {
	opacity: 0.7;
}

.fa-facebook {
	background: #3b5998;
	color: white;
}

.fa-twitter {
	background: #55acee;
	color: white;
}

.fa-google {
	background: #dd4b39;
	color: white;
}

.fa-linkedin {
	background: #007bb5;
	color: white;
}

.fa-instagram {
	background: #8a2387;
	/* fallback for old browsers */
	background: -webkit-linear-gradient(to right, #f27121, #e94057, #8a2387);
	/* Chrome 10-25, Safari 5.1-6 */
	background: linear-gradient(to right, #f27121, #e94057, #8a2387);
	/* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
	color: white;
}

.fa-github {
	background: #24292e;
	color: white;
}

.SkillAnim {
	font-family: Righteous;
	color: #ffaf00;
	font-size: 2rem;
	text-transform: uppercase;
	line-height: 1;
	animation: letterspacing 5s infinite alternate ease-in-out;
	display: block;
	position: absolute;
	left: 25%;
	top: 25%;
	background-color: #ffaf00;
	transform: rotate(20deg);
	transform: translate3d(-50%, -50%, 0) rotate(-20deg);
	letter-spacing: -2.2rem;
}

@keyframes letterspacing {
	0% {
		letter-spacing: -2.2rem;
		filter: blur(0.3rem);
	}

	50% {
		filter: blur(0.5rem);
	}

	100% {
		letter-spacing: 0.5rem;
		filter: blur(0rem);
		color: #ffaf00;
	}
}

.IntroSkills {
	/* width: 100%; */
	background-color: lawngreen;
	/* height: 100%; */
}

.SkillsLogo {
	display: block;
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: red;
	left: 20%;
	top: 50%;
	transform: translate3d(-50%, -50%, 0);
}

.SkillNameRight {
	font-family: Righteous;
	color: #cc5333;
	font-size: 4rem;
	text-transform: uppercase;
	line-height: 1;
	animation: letterspacing 5s infinite alternate ease-in-out;
	display: block;
	position: absolute;
	left: 70%;
	top: 35%;
	transform: translate3d(-50%, -50%, 0);
	letter-spacing: -2.2rem;
}

@keyframes letterspacing {
	0% {
		letter-spacing: -2.2rem;
		filter: blur(0.3rem);
	}

	50% {
		filter: blur(0.5rem);
	}

	100% {
		letter-spacing: 0.5rem;
		filter: blur(0rem);
		color: #23074d;
	}
}

.SkillsRight {
	display: block;
	position: absolute;
	left: 70%;
	top: 50%;
	transform: translate3d(-50%, -50%, 0);
}

.NewSpin {
	/* margin-top: 35%; */
	animation: App-logo-spin infinite 20s linear;
	position: absolute;
	bottom: 35%;
	left: 39%;
	/* width: 20%; */
	pointer-events: none;
}

.ReactlogoCustom {
	width: 150px;
	height: 150px;
}

.Reduxlogosize {
	width: 100px;
	height: 100px;
}


@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}





.ReducLogoImage {
	/* margin-top: 35%; */
	animation: App-logo-spin infinite 20s linear;
	position: absolute;
	bottom: 39%;
	left: 42%;
	/* right: 20px;
	top: 50px; */
	pointer-events: none;
}

@-webkit-keyframes redux-logo-spin {
	0% {
		transform: translatex(-30px) translatey(0px);
	}

	50% {
		transform: translatex(-70px) translatey(70px);
	}

	100% {
		transform: translatex(-30px) translatey(0px);
	}
}

@-moz-keyframes redux-logo-spin {
	0% {
		transform: translatex(-30px) translatey(0px);
	}

	50% {
		transform: translatex(-70px) translatey(70px);
	}

	100% {
		transform: translatex(-30px) translatey(0px);
	}
}

@-o-keyframes redux-logo-spin {
	0% {
		transform: translatex(-30px) translatey(0px);
	}

	50% {
		transform: translatex(-70px) translatey(70px);
	}

	100% {
		transform: translatex(-30px) translatey(0px);
	}
}

@keyframes redux-logo-spin {
	0% {
		transform: translatex(-30px) translatey(0px);
	}

	50% {
		transform: translatex(-70px) translatey(70px);
	}

	100% {
		transform: translatex(-30px) translatey(0px);
	}
}

.NodejsLogo {
	position: absolute;
	bottom: 0;
	right: 0;
}

.MongodbLogo {
	position: absolute;
	bottom: 200px;
	left: 50px;
}

.NodejsLogo {
	/*border: solid 1px Red;*/
	height: 600px;
	width: 300px;
	position: absolute;
	z-index: 4;
	top: 50%;
	left: 50%;
	margin-top: -300px;
	margin-left: -160px;
	border-radius: 100%;
	/* background-color: hotpink; */
	-webkit-animation: Rotation 30s linear infinite;
	animation: Rotation 30s linear infinite;
}

@-webkit-keyframes Rotation {
	from {
		-webkit-transform: rotate(0);
		transform: rotate(0);
	}

	to {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@keyframes Rotation {
	from {
		-webkit-transform: rotate(0);
		transform: rotate(0);
	}

	to {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

.Nodejslogosize {
	position: absolute;
	top: 50%;
	left: 0;
	height: 80px;
	width: 80px;
	margin-top: -40px;
	-webkit-animation: Rotation 3s linear infinite;
	animation: Rotation 3s linear infinite;
}

.MonogoLogodesign {
	width: 30px;
	height: 40px;
}

.MongodbLogo {
	width: 50px;
	top: 10%;
	left: 50%;
	height: '100%';
	position: absolute;
	display: block;
	animation: animate 15s linear infinite;
}


@keyframes animate {
	0% {
		top: 8%;
	}

	50% {
		top: 78%;
	}

	100% {
		top: 8%;
	}
}


.ExpressjsLogo {
	/*border: solid 1px red;*/
	height: 120px;
	width: 120px;
	position: absolute;
	z-index: 4;
	top: 50%;
	left: -20px;
	margin-top: -60px;
	border-radius: 100%;
	-webkit-animation: Rotation 5s linear infinite reverse;
	animation: Rotation 5s linear infinite reverse;
}

.EXpresslogosize {
	height: 40px;
	width: 40px;
	background: radial-gradient(#DDD, #888);
	border-radius: 100%;
}







/* @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  } */

/* implementing the media querry for reponsive /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////*/
@media only screen and (max-width: 600px) {
	.Container {
		width: 100%;
		height: 90%;
		background: #1c92d2;
		/* fallback for old browsers */
		background: -webkit-linear-gradient(to right, #f2fcfe, #1c92d2);
		/* Chrome 10-25, Safari 5.1-6 */
		background: linear-gradient(to right, #f2fcfe, #1c92d2);
		/* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
	}

	.SocialSite {
		position: absolute;
		/* display: flex; */
		justify-content: center;
		/* align-items: center; */
		right: 7%;
		top: 10%;
		/* height: 50%; */
		/* width: 7%; */
	}

	.Project {
		width: 40%;
		height: 40vh;
		background-color: aqua;
		margin-top: 3%;
		opacity: 1;
		justify-content: center;
		display: flex;
		background-image: url('../../images/images.jpeg');
	}

	.Button {
		width: 100px;
		height: 20px;
		border: none;
		outline: none;
		color: #fff;
		transform: translate(50%, 50%, 0, 0);
		cursor: pointer;
		visibility: hidden;
		position: relative;
		z-index: 0;
		border-radius: 10px;
		background: #c31432;
		/* fallback for old browsers */
		background: -webkit-linear-gradient(to right, #240b36, #c31432);
		/* Chrome 10-25, Safari 5.1-6 */
		background: linear-gradient(to right, #240b36, #c31432);
		/* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
	}

	.Button::before {
		content: '';
		background: linear-gradient(45deg,
				#ff0000,
				#ff7300,
				#fffb00,
				#48ff00,
				#00ffd5,
				#002bff,
				#7a00ff,
				#ff00c8,
				#ff0000);
		position: absolute;
		top: -2px;
		left: -2px;
		background-size: 400%;
		z-index: -1;
		filter: blur(5px);
		width: calc(100% + 4px);
		height: calc(100% + 4px);
		animation: glowing 20s linear infinite;
		opacity: 0;
		transition: opacity 0.3s ease-in-out;
		border-radius: 10px;
	}

	.Text {
		position: absolute;
		top: -20x;
		right: -20px;
		width: 200px;
		height: 400px;
		font-size: 200px;
		z-index: 1;
		color: #002bff;
		visibility: hidden;
	}

	.Title {
		font-size: 30px;
		color: blue;
		visibility: hidden;
		position: absolute;
		bottom: 40%;
		width: 50%;
		left: 5%;
	}

	.Description {
		font-size: 20px;
		color: blue;
		visibility: hidden;
		position: absolute;
		bottom: 30%;
		width: 50%;
		/* height: 100%; */
		left: 5%;
	}

	.Button:active {
		background: #c31432;
		/* fallback for old browsers */
		background: -webkit-linear-gradient(to right, #240b36, #c31432);
		/* Chrome 10-25, Safari 5.1-6 */
		background: linear-gradient(to right, #240b36, #c31432);
		/* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
	}

	.Button:hover::before {
		opacity: 1;
	}

	.Button::after {
		z-index: -1;
		content: '';
		position: absolute;
		width: 0%;
		height: 0%;
		background: #111;
		left: 10%;
		bottom: 10%;
		border-radius: 10px;
	}

	@keyframes glowing {
		0% {
			background-position: 0 0;
		}

		50% {
			background-position: 400% 0;
		}

		100% {
			background-position: 0 0;
		}
	}

	.ProjectRight {
		position: relative;
		transition: all 1s;
		overflow: hidden;
		width: 40%;
		height: 40vh;
		z-index: 2;
		background-color: aqua;
		margin-top: 3%;
		margin-bottom: 2%;
		float: right;
		justify-content: center;
		display: flex;
		/* background-image: url('../../images/images.jpeg') */
	}

	.ProjectRight::after {
		content: '';
		position: absolute;
		left: 0;
		top: 10%;
		width: 100%;
		height: 100%;
		bottom: 0;
		z-index: -2;
		transition: all 0.35s;
	}

	.ProjectRight::before {
		content: '';
		/* width: 0%; */
		height: 100%;
		position: absolute;
		left: 10%;
		bottom: 10%;
		z-index: -2;
	}

	.Project:hover {
		color: #002bff;
	}

	.Project:hover::after {
		width: 100%;
	}

	.ProjectLeft {
		display: block;
		width: 100%;
		/* height: 100%; */
		/* width: 60%; */
		opacity: 1;
		margin-top: 10%;
		/* background-image: url("../images/images.jpeg"); */
		height: 45%;

		margin-left: auto;
		margin-right: auto;
		background-position: 50%;
		background-size: cover;
		background-repeat: no-repeat;
		transition: all 0.35s ease;
	}

	.Image {
		width: 100%;
		height: 100%;
	}

	.ProjectLeft::before {
		content: '';
		position: absolute;
		width: 0%;
		top: 0px;
		opacity: 0.5;
		right: 0px;
		height: 100%;
		background-color: #77a1d3;
		transition: width 0.3s;
	}

	.ProjectLeft:hover::before {
		width: 100%;
		height: 100%;
	}

	.ProjectLeft:hover .Text {
		visibility: visible;
	}

	.ProjectLeft:hover .Button {
		visibility: visible;
	}

	.ProjectLeft:hover .Description {
		visibility: visible;
	}

	.ProjectLeft:hover .Title {
		visibility: visible;
	}

	.fa {
		/* padding: 20px; */
		font-size: 1.5em;
		width: 70%;
		height: 70%;
		text-align: center;
		text-decoration: none;
		margin: 10px 5px;
		border-radius: 50%;
	}

	.fa:hover {
		opacity: 0.7;
	}

	.fa-facebook {
		background: #3b5998;
		color: white;
	}

	.fa-twitter {
		background: #55acee;
		color: white;
	}

	.fa-google {
		background: #dd4b39;
		color: white;
	}

	.fa-linkedin {
		background: #007bb5;
		color: white;
	}

	.fa-instagram {
		background: #8a2387;
		/* fallback for old browsers */
		background: -webkit-linear-gradient(to right, #f27121, #e94057, #8a2387);
		/* Chrome 10-25, Safari 5.1-6 */
		background: linear-gradient(to right, #f27121, #e94057, #8a2387);
		/* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
		color: white;
	}

	.fa-github {
		background: #24292e;
		color: white;
	}

	.SkillAnim {
		font-family: Righteous;
		color: #ffaf00;
		font-size: 1.5rem;
		text-transform: uppercase;
		line-height: 1;
		animation: letterspacing 4s infinite alternate ease-in-out;
		display: block;
		position: absolute;
		left: 20%;
		top: 30%;
		transform: translate3d(-50%, -50%, 0);
		letter-spacing: -2.2rem;
	}

	@keyframes letterspacing {
		0% {
			letter-spacing: -2.2rem;
			filter: blur(0.3rem);
		}

		50% {
			filter: blur(0.5rem);
		}

		100% {
			letter-spacing: 0.5rem;
			filter: blur(0rem);
			color: #ffaf00;
		}
	}

	.SkillsLogo {
		display: block;
		width: 100%;
		height: 100%;
		position: absolute;
		background-color: black;
		left: 20%;
		top: 50%;
		transform: translate3d(-50%, -50%, 0);
	}

	.logodesigning {
		position: absolute;
		left: 0;
		right: 20px;
		top: 0;
		bottom: 0;
	}

	.HtmlcssSpin {
		/* height: 100px;
      width: 100px; */
		margin-top: 20%;
	}

	.skillsLogoDesig {
		height: 100px;
		width: 100px;
	}

	.SkillNameRight {
		font-family: Righteous;
		color: #cc5333;
		font-size: 1.5rem;
		text-transform: uppercase;
		line-height: 1;
		animation: letterspacing 5s infinite alternate ease-in-out;
		display: block;
		position: absolute;
		left: 20%;
		top: 85%;
		transform: translate3d(-50%, -50%, 0);
		letter-spacing: -2.2rem;
	}

	@keyframes letterspacing {
		0% {
			letter-spacing: -2.2rem;
			filter: blur(0.3rem);
		}

		50% {
			filter: blur(0.5rem);
		}

		100% {
			letter-spacing: 0.5rem;
			filter: blur(0rem);
			color: #23074d;
		}
	}

	/* .SkillsRight{
    display: block;
    position: absolute;
    height: 50px;
    width: 100px;
    left: 20%;
     top: 65%;
   transform: translate3d(-50%, -50%, 0);
} */
	.NewSpin {
		position: absolute;
		/* top: 35%; */
		/* left: 0; */
		animation: App-logo-spin infinite 20s linear;
		/* height: 5vmin; */
		pointer-events: none;
	}

	@keyframes App-logo-spin {
		from {
			transform: rotate(0deg);
		}

		to {
			transform: rotate(360deg);
		}
	}

	.ReducLogoImage {
		animation: redux-logo-spin infinite 20s linear;
		position: absolute;
		top: 40%;
		/* left: 0; */
		height: 5vmin;
		pointer-events: none;
	}

	@keyframes redux-logo-spin {
		from {
			transform: rotate(0deg);
		}

		to {
			transform: rotate(360deg);
		}
	}

	.Reduxlogosize {
		width: 50px;
		height: 50px;
	}

	.ReactlogoCustom {
		width: 100px;
		width: 100px;
	}

	.MongodbLogo {
		position: absolute;
		bottom: 5px;
		left: 45%;
	}

	.MonogoLogodesign {
		width: 50px;
		height: 70px;
		position: absolute;
		display: block;
		animation: animate 15s linear infinite;
	}

	.MongodbLogo {
		width: 50px;
		height: 70px;
		position: absolute;
		display: block;
		animation: animate 15s linear infinite;
	}

	.MongodbLogo div:nth-child(1) {
		left: 20%;
		animation: animate 15s linear infinite;
	}

	.MonogoLogodesign:nth-child(2) {
		left: 50%;
	}

	.MonogoLogodesign:nth-child(3) {
		left: 70%;
	}

	.MonogoLogodesign:nth-child(4) {
		left: 0%;
	}

	.MonogoLogodesign:nth-child(5) {
		left: 85%;
	}

	.MonogoLogodesign:nth-child(6) {
		left: 0%;
	}

	.MonogoLogodesign:nth-child(7) {
		left: 15%;
	}

	.MonogoLogodesign:nth-child(8) {
		left: 60%;
	}

	@keyframes animate {
		0% {
			top: -10%;
		}

		100% {
			top: 110%;
		}
	}

	.HomepageloweContainer {
		width: 100%;
		height: 100vh;
		display: block;
		flex-direction: row;
		background: #1c92d2;
		/* fallback for old browsers */
		background: -webkit-linear-gradient(to right, #f2fcfe, #1c92d2);
		/* Chrome 10-25, Safari 5.1-6 */
		background: linear-gradient(to right, #f2fcfe, #1c92d2);
		/* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
	}

	.HtmlcssSpin {
		margin-top: 20%;
	}
}