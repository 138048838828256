.ContactHomepage {
    width: "100%";
    height: 100vh;

}

.ContactLeftHalf {
    /* width: 50%; */
    /* height: 100%; */
    position: absolute;
    /* top: 5%; */
    left: 0;
    z-index: -10;
    top: 0;
    background: #780206;
    transition-delay: 20s;
    background: -webkit-linear-gradient(to bottom, #061161, #780206);
    background: linear-gradient(to bottom, #061161, #780206);
    display: flex;
    width: 100%;
    height: 100%;
    animation-duration: 2s;
    transition: width 2s ease-in;
    animation-name: contact-right;
    -webkit-clip-path: polygon(0 0, 76% 0, 24% 100%, 0% 100%);
    clip-path: polygon(0 0, 60% 0, 40% 100%, 0% 100%);

}

@keyframes contact-right {
    from {
        width: 0%;
    }

    to {
        width: 100%;
    }
}

.ContactRightHalf {
    position: absolute;
    /* top: 5%; */
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #d9b596;
    -webkit-clip-path: polygon(76% 0, 100% 0, 100% 100%, 24% 100%);
    clip-path: polygon(60% 0, 100% 0, 100% 100%, 40% 100%);
    background: #3a6186;
    animation: contact-left 2s linear;
    transition: width 2s ease-in;
    background: -webkit-linear-gradient(to top, #89253e, #3a6186);
    background: linear-gradient(to top, #89253e, #3a6186);
    flex-direction: column,
}


@keyframes contact-left {
    from {
        width: 0%;
    }

    to {
        width: 100%;
    }
}

.ContactMe {
    position: absolute;
    right: 20%;
    display: flex;
    align-items: center;
    /* justify-content: center; */
    flex-direction: column;
    animation: contact-text-anmi 2s linear;
    overflow: hidden;
    -webkit-animation-delay: 2s;
    -moz-animation-delay: 2s;
    animation-delay: 2s;
    transform: rotate(-20deg);
    top: 25%;
}

.ContactHeading {
    color: #9E9E9E;
    font-size: 2em;
}

.EmailDisplay {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    width: fit-content;
    margin-top: 15%;
}

.InputText {
    font-size: 1vw;
    color: #9E9E9E
}

.ContactMainContainer {
    width: 100%;
    height: 94%;
    margin-top: 5%;
    /* position: relative; */
    display: flex;
}

.image--cover {
    width: 60%;
    height: 100%;
    border-radius: 20%;
    margin-top: 20%;
    margin-left: 5%;
    /* margin-right: 90%; */
    /* margin:blo 40%; */

    object-fit: cover;
    object-position: center right;
}

.InputContent {
    /* background-color: brown; */
    height: 50%;
    width: 50%;
    display: block;
}

.ButtonDiv {
    width: 100%;
    display: flex;
    justify-content: center;
}

.InputName {
    color: #ffff;
}