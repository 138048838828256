::-webkit-scrollbar {
    display: none;
}

body {
    margin: 0;
}

.Div0 {
    background-image: url(../../images/bg.jpg);
}

.Div1 {
    background-image: url(../../images/sun.png);
}

.Div2 {
    background-image: url(../../images/birds.png);
}

.Div3 {
    background-image: url(../../images/mountain.png);
}

.Div4 {
    background-image: url(../../images/valley1.png);
}

.Div5 {
    background-image: url(../../images/valley2.png);
}

.Div6 {
    background-image: url(../../images/valley3.png);
}

.Div7 {
    background-image: url(../../images/valley4.png);
}

.Div8 {
    background-image: url(../../images/parallax6.png);
}

.Div8 {
    background-image: url(../../images/parallax7.png);
}

.Div9 {
    background-image: url(../../images/parallax8.png);
}

#parallax-container {
    height: 1000px;
    display: block;
}

#parallax-container div {
    position: fixed;
    /* background-size: auto 1038px; */
    top: 0;
    /* z-index: 10; */
    background-repeat: no-repeat;
    background-position: center !important;
    background-attachment: fixed;
    transform: translate3d(0, 0, 0);
    height: 1000px;
    width: 100%;
}

@media only screen and (max-width: 600px) {
    #parallax-container div {
        position: fixed;
        /* background-size: auto 1038px; */
        top: 0;
        /* z-index: 10; */
        background-repeat: no-repeat;
        background-position: center !important;
        background-attachment: fixed;
        transform: translate3d(0, 0, 0);
        height: 1000px;
        width: 100%;
    }

}