.MainContainer {
    width: 100%;
    height: 100vh;
    position: relative;
    display: flex;
}

.LeftHalf {
    width: 50%;
    /* height: 100vh; */
    background: #780206;
    background: -webkit-linear-gradient(to bottom, #061161, #780206);
    background: linear-gradient(to bottom, #061161, #780206);
    display: flex;
}

.RightHalf {
    width: 50%;
    /* height: 100vh; */
    background: #3a6186;
    background: -webkit-linear-gradient(to top, #89253e, #3a6186);
    background: linear-gradient(to top, #89253e, #3a6186);

    flex-direction: column,
}

.overlay {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 35%;
    height: 60vh;
    z-index: 10;
}