.AboutLeftHalf {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -10;
    background: #780206;
    transition-delay: 20s;
    background: -webkit-linear-gradient(to bottom, #061161, #780206);
    background: linear-gradient(to bottom, #061161, #780206);
    display: flex;
    width: 100%;
    height: 100%;
    animation-duration: 2s;
    transition: width 2s ease-in;
    animation-name: about-right;
    -webkit-clip-path: polygon(0 0, 76% 0, 24% 100%, 0% 100%);
    clip-path: polygon(0 0, 60% 0, 40% 100%, 0% 100%);
}

@keyframes about-right {
    from {
        width: 0%;
    }

    to {
        width: 100%;
    }
}

.AboutRightHalf {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: #d9b596;
    -webkit-clip-path: polygon(76% 0, 100% 0, 100% 100%, 24% 100%);
    clip-path: polygon(60% 0, 100% 0, 100% 100%, 40% 100%);
    background: #3a6186;
    animation: about-right 2s linear;
    transition: width 2s ease-in;
    background: -webkit-linear-gradient(to top, #89253e, #3a6186);
    background: linear-gradient(to top, #89253e, #3a6186);
    flex-direction: column;
}

@keyframes about-right {
    from {
        width: 0%;
    }

    to {
        width: 100%;
    }
}

.AboutMainContainer {
    width: 100%;
    height: 94%;
    /* position: relative; */
    display: flex;
}

.AboutMeWithHeade {
    width: 100%;
    height: 100vh;
}

.InputContent {
    height: 50%;
    width: 25%;
    display: block;
    position: absolute;
    right: 10%;
    animation-delay: 2s;
    transform: rotate(-20deg);
    top: 25%;
    /* background-color: green; */
}

.ButtonDiv {
    width: 100%;
    display: flex;
    justify-content: center;
}

.InputName {
    color: #ffff;
}

.ImageCover {
    /* width: 65%; */
    height: 30%;
    border-radius: 50%;
    margin-top: 20%;
    margin-left: 10%;
    object-fit: cover;
    object-position: center right;
}

.AboutLeftFirst {
    /* width: 50%; */
    height: 100%;
}

.AboutLeftLast {
    display: flex;
    justify-content: center;
    /* background-color: red; */
    /* width: 50%; */
    height: 100%;
}

.AboutRightTop {
    width: 30%;
    position: absolute;
    bottom: 10%;
    left: 5%;
    /* margin-top: 20%; */
    /* background-color: green; */
    /* animation: about-text-anmi 2s linear; */
    /* margin-right: 10%; */
    transform: rotate(20deg);
    color: #ffff;
}

@keyframes about-text-anmi {
    0% {
        transform: translateX(0px);
    }

    100% {
        transform: translateX(100%);
    }
}

p {
    font-size: 1em;
    color: #ffff;
}

@media only screen and (max-width: 600px) {


    .AboutMainContainer {
        width: 100%;
        height: 85%;
        /* position: relative; */
        display: flex;
    }

    .InputContent {
        height: 50%;
        width: 25%;
        display: block;
        position: absolute;
        right: 10%;
        animation-delay: 2s;
        transform: rotate(0deg);
        top: 25%;
        /* background-color: green; */
    }

    .AboutRightTop {
        width: 55%;
        position: absolute;
        bottom: 10%;
        left: 5%;
        /* margin-top: 20%; */
        /* background-color: green; */
        /* animation: about-text-anmi 2s linear; */
        /* margin-right: 10%; */
        transform: rotate(0deg);
        color: #ffff;
    }


}