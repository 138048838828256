.docs-demo-device.md figure {
    border-radius: 20px;
}

.docs-demo-device>figure {
    box-shadow: rgb(9, 10, 13) 0px 0px 0px 14px, rgb(159, 163, 168) 0px 0px 0px 17px, rgba(0, 0, 0, 0.2) 0px 0px 34px 17px;
    height: 0px;
    /* max-width: 320px;
    min-width: 320px; */
    padding-bottom: calc(var(--device-aspect-ratio) * 100%);
    position: relative;
    /* width: calc((100vh - var(--header-height) - var(--device-padding) * 2) / var(--device-aspect-ratio)); */
    z-index: 1;
    width: 30%;
    transform: translateX(50%);
    border-radius: 32px;
    margin: 2px;
    overflow: hidden;
}


figure {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 40px;
    margin-inline-end: 40px;
}

iframe {
    background-color: rgba(0, 0, 0, .02);
    /* -webkit-box-shadow: 10px 10px 20px rgba(0,0,0,.1); */
    box-shadow: 100px 100px 200px rgba(0, 0, 0, .1);
    width: 100%;
    height: 100%;
}



.docs-demo-device {
    --device-aspect-ratio: 0.650;
    --device-padding: 2rem;
    /* width: 320px; */
    position: sticky;
    top: 4vh;

}

.docs-demo-device__ios-notch {
    fill: #090a0d;
    top: -1px;
    left: 50%;
    position: absolute;
    width: 165px;
    transform: translateX(-50%);
    z-index: 2;
}

/* .docs-demo-device>figure>iframe {
    border: none;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
    border-radius: 30px;
  } */
iframe {
    background-color: rgba(0, 0, 0, .02);
    -webkit-box-shadow: 10px 10px 20px rgba(0, 0, 0, .1);
    box-shadow: 10px 10px 20px rgba(0, 0, 0)
}

/* .docs-demo-device__ios-notch {
    display: none;
    left: 50%;
    position: absolute;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  } */
hydrated {
    visibility: inherit;
}

.App-link {
    color: #09d3ac;
}

@media only screen and (max-width:600px) {

    .docs-demo-device {
        --device-aspect-ratio: 0.750;
        --device-padding: 2rem;
        /* width: 320px; */
        position: sticky;


    }

    .docs-demo-device>figure {
        box-shadow: rgb(9, 10, 13) 0px 0px 0px 14px, rgb(159, 163, 168) 0px 0px 0px 17px, rgba(0, 0, 0, 0.2) 0px 0px 34px 17px;
        height: 0px;
        /* max-width: 320px;
    min-width: 320px; */
        padding-bottom: calc(var(--device-aspect-ratio) * 100%);
        position: relative;
        /* width: calc((100vh - var(--header-height) - var(--device-padding) * 2) / var(--device-aspect-ratio)); */
        z-index: 1;
        width: 45%;
        transform: translateX(50%);
        border-radius: 32px;
        margin: 2px;
        overflow: hidden;
    }


}