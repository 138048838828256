.navigation {

  width: 100%;
  display: flex;
  /* height: 100%; */
  position: relative;
  /* z-index: 5; */
  float: right;
  /* height: 7vh; */
  /* background: #780206;
  background: -webkit-linear-gradient(to right, #061161, #780206);
  background: linear-gradient(to right, #061161, #780206); */
}

/* .LogoStyle{
    width: 20%;
    height: 100%;
} */
.custom-underline {
  position: relative;
  display: inline-block;
  font-size: 24px;
  font-weight: 400;
  text-align: center;
}

a {
  color: white;
}

.navbar-light .navbar-nav .nav-link {
  color: '#b6b6b6';

  font-size: '3vh';
  /* background-color: aqua; */
  /* width: 100%;
height: 100%; */
}

.nav-item::after {
  top: 65%;
  position: absolute;
  height: 2px;
  width: 0%;
  left: 5%;
  background-color: rgba(255, 255, 255, 0.6);
  transition: 0.5s ease all .3s;
}

.nav-item:hover .custom-underline::after {
  width: 90%;
  transition: 0.3s ease all;
}

.navigation-detail {
  width: 100%;
  /* height: 2vh; */
  display: flex;
  flex-direction: row;
}

.headerContainer {
  width: 100%;
  /* height: 7vh; */
  /* background: #780206;
    background: -webkit-linear-gradient(to right, #061161, #780206);
    background: linear-gradient(to right, #061161, #780206); */
}

@media only screen and (max-width: 600px) {

  .custom-underline {
    position: relative;
    display: inline-block;
    font-size: 24px;
    font-weight: 400;
    text-align: center;
  }

  a {
    color: white;
  }



  .navbar {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    z-index: 10;
    padding: 0;
  }



}