.logo-mask {
  position: absolute;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 40%;
  opacity: .6;
  z-index: 3;
}

#XMLID_4_ {
  stroke-dasharray: 548, 550;
  stroke-dashoffset: 0;
}

#XMLID_3_ {
  stroke-dasharray: 278, 280;
  stroke-dashoffset: 0;
}

#XMLID_2_ {
  stroke-dasharray: 926, 928;
  stroke-dashoffset: 0;
}

#XMLID_1_ {
  stroke-dasharray: 461, 463;
  stroke-dashoffset: 0;
}

#XMLID_12_ {
  offset: 0;
  stop-color: #2D2E83
}

#XMLID_10_ {
  offset: 1;
  stop-color: #2D2E83
}

.shapes-mask {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity .25s ease .65s;
  z-index: -3;
}

.logo-mask svg {
  width: 100%;
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.st0 {
  clip-path: url(#XMLID_8_);
  fill: none;
  stroke: url(#XMLID_9_);
  stroke-width: 70.344;
  stroke-miterlimit: 10;
  animation: new 5s linear forwards;
}

@keyframes new {
  to {
    stroke-dashoffset: 0;
  }
}

.st1 {
  clip-path: url(#XMLID_11_);
  fill: none;
  stroke: url(#XMLID_12_);
  stroke-width: 70.344;
  stroke-miterlimit: 10;
}

#XMLID_4 {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: dash 5s linear forwards;
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}

/* media querry example ///////////////////////////////////////////////////////////////////*/
@media only screen and (max-width: 600px) {
  .logo-mask {
    position: absolute;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    top: 40%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 89%;
    opacity: .6;
    z-index: 3;
  }

  #XMLID_4_ {
    stroke-dasharray: 548, 550;
    stroke-dashoffset: 0;
  }

  #XMLID_3_ {
    stroke-dasharray: 278, 280;
    stroke-dashoffset: 0;
  }

  #XMLID_2_ {
    stroke-dasharray: 926, 928;
    stroke-dashoffset: 0;
  }

  #XMLID_1_ {
    stroke-dasharray: 461, 463;
    stroke-dashoffset: 0;
  }

  #XMLID_12_ {
    offset: 0;
    stop-color: #2D2E83
  }

  #XMLID_10_ {
    offset: 1;
    stop-color: #2D2E83
  }

  .shapes-mask {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: opacity .25s ease .65s;
    z-index: -3;
  }

  .logo-mask svg {
    width: 100%;
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  .st0 {
    clip-path: url(#XMLID_8_);
    fill: none;
    stroke: url(#XMLID_9_);
    stroke-width: 70.344;
    stroke-miterlimit: 10;
    animation: new 5s linear forwards;
  }

  @keyframes new {
    to {
      stroke-dashoffset: 0;
    }
  }

  .st1 {
    clip-path: url(#XMLID_11_);
    fill: none;
    stroke: url(#XMLID_12_);
    stroke-width: 70.344;
    stroke-miterlimit: 10;
  }

  #XMLID_4 {
    stroke-dasharray: 1000;
    stroke-dashoffset: 1000;
    animation: dash 5s linear forwards;
  }

  @keyframes dash {
    to {
      stroke-dashoffset: 0;
    }
  }
}