body {
  background: #000510;
  font-family: 'Titillium Web', sans-serif;
  width: 100%;
  height: 100%;
}

.container {
  margin: 0 auto 40px;
  display: table;
}

.st0 {
  fill: #23262C;
  stroke: #171A1E;
  stroke-width: 5;
  stroke-miterlimit: 10;
}

.st1 {
  fill: #344156;
  stroke: #121316;
  stroke-width: 5;
  stroke-miterlimit: 10;
}

.st2 {
  opacity: 0.77;
  fill: none;
  stroke: #2F343F;
  stroke-width: 6;
  stroke-miterlimit: 10;
  stroke-dasharray: 6.0121, 6.0121;
  enable-background: new;
}

.st3 {
  opacity: 0.32;
  fill: #1B1E25;
  stroke: #49535E;
  stroke-width: 2;
  stroke-miterlimit: 10;
  enable-background: new;
}

.st4 {
  fill: #12161E;
  stroke: #3F454F;
  stroke-width: 5;
  stroke-miterlimit: 10;
}

.st5 {
  opacity: 0.7;
  fill: url(#SVGID_1_);
  enable-background: new;
}

.st6 {
  fill: #12161E;
}

.st7 {
  fill: #414751;
}

.st8 {
  fill: none;
}

.st9 {
  opacity: 0.74;
  fill: #1B1E25;
  stroke: #49535E;
  stroke-miterlimit: 10;
  enable-background: new;
}

.st10 {
  fill: #FF3C00;
}

.st11 {
  opacity: 0.32;
}

.st12 {
  fill: #1B1E25;
  stroke: #49535E;
  stroke-miterlimit: 10;
}

.st13 {
  opacity: 0.42;
  fill: #000510;
  enable-background: new;
}

.st14 {
  fill: #931429;
}

.st15 {
  fill: #253E5B;
}

.st16 {
  opacity: 0.2;
  fill: url(#SVGID_2_);
  enable-background: new;
}

.st17 {
  fill: #2B3647;
}

.st18 {
  opacity: 0.33;
}

.st19 {
  fill: #8797AD;
}

.st20 {
  fill: none;
  stroke: none;
}

#svg {
  width: 825px;
  margin: 0 auto;
  display: table;
  position: absolute;
  z-index: 1;
}

.inlinesvg .fallback {
  display: none;
  visibility: hidden;
}

.st0 {
  fill: #23262C;
  stroke: #171A1E;
  stroke-width: 5;
  stroke-miterlimit: 10;
}

.st1 {
  fill: #344156;
  stroke: #121316;
  stroke-width: 5;
  stroke-miterlimit: 10;
}

.st2 {
  opacity: 0.77;
  fill: none;
  stroke: #2F343F;
  stroke-width: 6;
  stroke-miterlimit: 10;
  stroke-dasharray: 6.0121, 6.0121;
  enable-background: new;
}

.st3 {
  opacity: 0.32;
  fill: #1B1E25;
  stroke: #49535E;
  stroke-width: 2;
  stroke-miterlimit: 10;
  enable-background: new;
}

.st4 {
  fill: #12161E;
  stroke: #3F454F;
  stroke-width: 5;
  stroke-miterlimit: 10;
}

.st5 {
  opacity: 0.7;
  fill: url(#SVGID_1_);
  enable-background: new;
}

.st6 {
  fill: #12161E;
}

.st7 {
  fill: #414751;
}

.st8 {
  fill: none;
}

.st9 {
  opacity: 0.74;
  fill: #1B1E25;
  stroke: #49535E;
  stroke-miterlimit: 10;
  enable-background: new;
}

.st10 {
  fill: #FF3C00;
}

.st11 {
  opacity: 0.32;
}

.st12 {
  fill: #1B1E25;
  stroke: #49535E;
  stroke-miterlimit: 10;
}

.st13 {
  opacity: 0.42;
  fill: #000510;
  enable-background: new;
}

.st14 {
  fill: #931429;
}

.st15 {
  fill: #253E5B;
}

.st16 {
  opacity: 0.2;
  fill: url(#SVGID_2_);
  enable-background: new;
}

.st17 {
  fill: #2B3647;
}

.st18 {
  opacity: 0.33;
}

.st19 {
  fill: #8797AD;
}

.st20 {
  fill: none;
  stroke: none;
}

.no-inlinesvg .fallback {
  width: 500px;
  height: 500px;
  display: block;
}

.no-inlinesvg .fallback img {
  width: 100%;
}

h2 {
  position: relative;
  z-index: 100;
  color: #28384F;
  font-size: 25px;
  line-height: 1.2em;
  margin: 0;
}

.dialog {
  color: #BEEAE6;
  letter-spacing: 0.03em;
  position: absolute;
  z-index: 200;
  text-align: right;
  opacity: 0.7;
  margin-top: -80px;
  margin-left: 585px;
  width: 180px;
}

.dialog h3 {
  margin-bottom: 10px;
}

.dialog p {
  padding-top: 0px;
  margin-top: 0px;
}

.dialog p.smaller {
  font-size: 14px;
  line-height: 1.25em;
}

.dialog {
  visibility: hidden;
}

.sub {
  font-size: 60%;
  vertical-align: sub;
}

.iconCircle {
  border-right-color: transparent;
  animation: spin 20s linear infinite;

}

@keyframes spin {
  100% {
    transform: rotate(360deg)
  }
}

.st4 {
  animation: daily 10s infinite;
}

@keyframes daily {
  from {
    transform: rotate(0deg)
  }

  to {
    transform: rotate(360deg);
  }
}

.st7 {
  animation: daily 10s infinite;
}

@keyframes daily {
  from {
    transform: rotate(0deg)
  }

  to {
    transform: rotate(360deg);
  }
}

.button,
.button:visited,
.button:active {
  z-index: 2000;
  position: absolute;
  background: #23262C;
  color: #49535E;
  font-weight: 600;
  letter-spacing: 0.02em;
  padding: 5px 16px 5px;
  letter-spacing: 0.03em;
  border-radius: 5px;
  min-height: 26px;
  margin-right: 10px;
  display: inline-block;
  font-size: 15px;
  font-weight: 400;
  text-align: center;
  text-decoration: none;
  border-bottom: 1px solid #000;
  outline: 0;
  cursor: pointer;
  -webkit-transition: none;
  transition: all 0.5s ease;
}

.button:hover,
.button:visited:hover,
.button:active:hover {
  background: #2e323a;
}

#slider {
  opacity: 0.6;
  position: absolute;
  margin: 540px auto 50px;
  display: table;
  z-index: 510;
  width: 510px;
  height: 8px;
  background: rgba(80, 80, 80, 0.3);
  border: 1px solid rgba(102, 102, 102, 0.5);
}

.ui-slider-handle {
  border-radius: 2px;
  background: #7A9293;
  position: absolute;
  display: block;
  margin-top: -3px;
  width: 14px;
  height: 14px;
  outline: none;
}

.money-sm {
  width: 90px;
  position: absolute;
  left: 8%;
  margin-top: 215px;
}

@media (max-width: 475px) {

  #svg,
  .fallback {
    top: 320px;
  }
}

@media (max-width: 825px) {
  .dialog {
    left: 5%;
    text-align: center;
    width: 90%;
    display: table;
    margin: 0 !important;
  }

  .button,
  .button:visited,
  .button:active {
    margin-top: 230px;
    left: 9%;
  }

  #svg,
  .fallback {
    top: 270px !important;
    width: 100%;
    left: 6%;
  }

  container {
    width: 100%;
  }

  #slider {
    margin: 120px auto 20px;
    width: 90%;
    left: 6%;
  }

  h2 {
    padding-top: 30px;
    width: 100%;
    text-align: center;
  }

  #box,
  .money-lg,
  .million {
    display: none;
  }
}

@media (min-width: 826px) {
  .money-sm {
    display: none;
  }

  .button,
  .button:visited,
  .button:active {
    margin-top: 400px;
    left: 64.95%;
  }

  #slider {
    margin: 540px auto 50px;
    width: 510px;
  }

  .container {
    width: 825px;
  }

  h2 {
    width: 120px;
    padding-top: 25px;
  }
}